.pin-input {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(0, 1fr);
  gap: var(--size-small-x);

  @for $i from 4 through 10 {
    &--#{$i} {
      grid-template-columns: repeat($i, 1fr);
      gap: var(--size-small-x);
    }
  }

  &__input {
    width: 100%;
    height: 68px;
    padding: 0;
    margin: 0;
    text-align: center;
    background: none;
    border: var(--border-primary);
    border-radius: 10px;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.65px;
    color: var(--body-primary);

    &:focus {
      outline: none;
      border: 2px solid var(--brand-primary);
    }
  }

  &.error > &__input {
    background-color: var(--red-alpha-3);
    color: var(--error-primary);
    border: none;
  }
}
