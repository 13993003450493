.ssh-key-item {
  display: flex;
  align-items: center;
  padding: var(--size-small);
  background-color: var(--background-primary);
  color: var(--body-primary);
  border-bottom: var(--border-primary);

  &:not(.selected) {
    &:hover {
      background-color: var(--background-secondary);
      cursor: pointer;
    }
  }

  &.selected {
    background-color: var(--background-secondary);
  }

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &__body {
    overflow: hidden;
    margin-right: var(--size-small);
  }

  &__key {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
