.profile-page-top-bar {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--size-small) var(--size-medium);
  border-bottom: var(--border-primary);
  background-color: var(--background-primary);

  &__back {
    margin-right: var(--size-medium);

    &-btn {
      path {
        fill: var(--body-primary);
      }
    }
  }

  &__title {
    &-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}
