.server-info-tab {
  display: flex;
  flex: 1 1 auto;
  padding: var(--size-medium);
  height: 100%;

  &__body {
    @extend %custom-scroll-x;

    display: flex;
    flex: 1 1 auto;
    background-color: var(--background-primary);
    overflow: auto;
    border: var(--border-primary);
    border-radius: 12px;
  }
}
