.auth-layout {
  display: flex;
  flex-direction: column;
  padding: var(--size-large-2x);
  max-width: 1300px;
  margin: auto;

  &__inner {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-large-5x);

    @include mobile-and-tablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
