.resize-plan-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--size-medium);
  border: var(--border-primary);
  border-radius: 8px;

  &__row {
    &:not(:last-of-type) {
      margin-bottom: var(--size-medium);
    }
  }

  &__arrow-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 75%);
    bottom: 0;
    z-index: 10;
  }

  &__title {
    display: inline-block;
    background-color: var(--background-secondary);
    padding: var(--size-small-2x) var(--size-small-x);
    border-radius: 8px;
    margin-bottom: var(--size-small-x) !important;
  }

  &__hardware-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-medium);

    &-item {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--size-small-3x);
      background-color: var(--background-secondary);
      width: 32px;
      height: 32px;
      max-width: 32px;
      max-height: 32px;
      border: var(--border-primary);
      border-radius: 8px;
      margin-right: var(--size-small-x);

      path {
        fill: var(--body-placeholder);
      }
    }
  }
}
