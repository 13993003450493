.auth-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--size-large-3x);

  &__logo {
    display: flex;
    align-items: center;
  }

  &__app-name {
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-style: normal;
    margin-left: 14px;
    line-height: 32px;
    letter-spacing: -0.8px;
    color: var(--body-primary);
  }
}
