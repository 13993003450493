.billing-details-viewer {
  position: relative;
  display: flex;
  height: 100%;
  padding: var(--size-medium);

  &__body {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    border: var(--border-primary);
    background-color: var(--background-primary);
    border-radius: 12px;

    @include mobile-and-tablet {
      flex-direction: column;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    padding: var(--size-medium);
    border-bottom: var(--border-primary);
  }

  &__side {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    flex: 1 1 100%;

    &--left {
      border-right: var(--border-primary);
      min-width: 470px;
      flex: 1 1 470px;
    }

    @include mobile-and-tablet {
      &--left {
        border-right: none;
        border-bottom: var(--border-primary);
      }
    }
  }
}
