.sidebar-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--size-medium);

  &__toggle {
    padding-right: 0 !important;

    path {
      fill: var(--body-placeholder);
    }

    &:hover {
      path {
        fill: var(--body-primary);
      }
    }

    .sidebarCollapsed & {
      display: none;
    }
  }
}
