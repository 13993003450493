.editable-server-name {
  display: flex;
  align-items: end;
  gap: var(--size-small-x);
  line-height: 0;

  &__inner {
    display: flex;
  }

  &__editable-name {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 33px;
    padding: 0 var(--size-small-x);
    max-width: 480px;
    border: 1px solid transparent;
    border-right: 0;
    border-radius: 8px 0 0 8px;

    &::after {
      position: absolute;
      content: '';
      height: 105%;
      right: -10px;
      width: 10px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    & .MuiTypography-root {
      overflow: hidden;
      white-space: nowrap;

      &:focus-visible {
        outline: none;
      }
    }
  }

  &.editing & {
    &__editable-name {
      border-color: var(--border-color-primary);
      min-width: 75px;

      &::after {
        border-color: var(--border-color-primary);
      }
    }
  }

  &__button {
    min-width: 33px !important;
    width: 33px !important;
    height: 33px !important;

    &--edit {
      path {
        fill: var(--body-primary);
      }
    }
  }
}
