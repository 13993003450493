.user-balance {
  display: flex;
  flex-direction: column;
  border: var(--border-primary);
  border-radius: 8px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &.hide {
    display: none;
  }

  &__head {
    display: flex;
    align-items: center;
    padding: var(--size-small);
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: center;
    padding: var(--size-small);
    border-top: var(--border-primary);
  }

  &__amount-label {
    color: var(--body-placeholder);
  }

  &__top-up-button {
    min-width: 0 !important;
    margin-left: auto !important;
    padding: 4px !important;
    border: var(--border-primary);
    border-radius: 8px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  &__plus-icon {
    fill: var(--body-primary);
  }
}
