*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  border-collapse: collapse;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
