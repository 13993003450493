.page-content {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1 1 auto;
  padding: var(--size-medium);

  &__wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--background-primary);
    border: var(--border-primary);
    border-radius: 12px;
  }

  &__inner {
    @extend %custom-scroll-x;

    overflow: auto;
    width: 100%;
    height: 100%;
  }
}
