.animated-tab {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &--hide {
    animation: fade-out-opacity 0.2s ease-out;
  }

  &--show {
    animation: fade-in-opacity 0.2s ease-in;
  }

  &--height100 {
    height: 100%;
  }
}
