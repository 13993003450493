.custom-scroll,
%custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0) transparent;
  transition: scrollbar-color 0.3s ease;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(90, 90, 90, 0);
    border-radius: 0.35rem;
  }

  &:hover,
  &:focus,
  &:focus-within {
    scrollbar-color: rgba(90, 90, 90, 0.3) transparent;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(90, 90, 90, 0.3);
    }
  }

  &::-webkit-scrollbar {
    width: 0.375rem;
  }
}

.custom-scroll-x,
%custom-scroll-x {
  @extend %custom-scroll;

  &::-webkit-scrollbar {
    height: 0.35rem;
  }
}

.custom-scroll-y,
%custom-scroll-y {
  overflow-y: auto;

  @extend %custom-scroll;
}

.chat-custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(90, 90, 90, 0) transparent;
  transition: scrollbar-color 0.3s ease;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(90, 90, 90, 0);
    border-radius: 0.35rem;
  }

  &:hover,
  &:focus,
  &:focus-within {
    scrollbar-color: rgba(90, 90, 90, 0.3) transparent;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(90, 90, 90, 0.3);
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
}
