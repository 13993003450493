.balance-operation-amount {
  display: flex;
  justify-content: end;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: var(--size-small-x);
    background-color: var(--success-alpha3);
    color: var(--success-primary);

    &.negative {
      background-color: var(--error-alpha3);
      color: var(--error-primary);
    }
  }
}
