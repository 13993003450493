.profile-menu {
  display: flex;
  flex-direction: column;
  border: var(--border-primary);
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  margin-top: var(--size-medium);

  .sidebarCollapsed & {
    border-radius: 8px;
    border-bottom: var(--border-primary);
  }

  &__bottom {
    display: flex;
    align-items: center;
    padding: var(--size-small);
    border-top: var(--border-primary);
    cursor: pointer;
  }

  &:not(.open) {
    border-top: 0;
  }

  &:not(.open) & {
    &__bottom {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  &__initials {
    @extend %label-3;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: var(--success-primary);
    border-radius: 50%;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 var(--size-small) !important;

    .sidebarCollapsed & {
      display: none;
    }
  }

  &__arrow {
    margin-left: auto;

    path {
      fill: var(--body-placeholder);
    }

    .sidebarCollapsed & {
      display: none;
    }
  }

  .open & {
    &__arrow {
      transform: rotate(180deg);
    }
  }
}
