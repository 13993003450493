body {
  font-family: var(--font-family);
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: var(--body-primary);
  background-color: var(--background-primary);
  overflow: hidden;
  margin: 0;
  text-align: left;
}

:root {
  --sidebar-width: 260px;
  --sidebar-collapsed-width: 82px;
  --filters-bar-width: 362px;
  --border-primary: 1px solid var(--border-color-primary);
  --border-secondary: 1px solid var(--border-color-secondary);
  --border-tertiary: 1px solid var(--border-color-tertiary);
  --system-alert-bar-height: 64px;
  --update-app-top-bar-height: 64px;
}

// .dark:root {
// }
