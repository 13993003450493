.authorized-layout-wrapper {
  position: relative;
}

.authorized-layout {
  display: flex;
  margin-left: var(--sidebar-collapsed-width);

  .sidebarStatic & {
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
  }

  .sidebarCollapsed & {
    width: calc(100% - var(--sidebar-collapsed-width));
  }
}
