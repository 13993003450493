.password-smart-input {
  width: 100%;
  margin-bottom: var(--size-medium);

  &__validations {
    margin-top: var(--size-small-2x);
    display: flex;
  }

  &__validation {
    @extend %body-3;

    display: flex;
    align-items: center;
    padding: var(--size-small-2x) var(--size-small-x);
    margin-right: var(--size-small-2x);
    border: var(--border-primary);
    border-radius: 8px;
    color: var(--body-placeholder);
    background-color: var(--background-secondary);

    svg {
      margin-right: var(--size-small-2x);

      path {
        fill: var(--body-placeholder);
      }
    }

    &.success {
      color: var(--body-tint);
      background-color: var(--success-primary);

      svg path {
        fill: var(--body-tint);
      }
    }

    &.error {
      color: var(--body-tint);
      background-color: var(--error-primary);

      svg path {
        fill: var(--body-tint);
      }
    }
  }
}
