.auth-illustration {
  display: flex;

  @include mobile-and-tablet {
    display: none;
  }

  &__inner {
    position: relative;
    flex: 1 1 auto;
    border-radius: 50px;
    overflow: hidden;
    background: url('../../../../assets/images/auth-illustration.png') no-repeat;
    background-size: cover;
    width: 640px;
    max-height: 640px;
    height: 100%;

    @media (width <= 1200px) {
      width: 500px;
      max-height: 500px;
    }

    .dark & {
      background: url('../../../../assets/images/auth-illustration-dark.png') no-repeat;
      background-size: cover;
    }
  }
}
