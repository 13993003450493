.pagination {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__pages {
    @extend %body-1;

    display: flex;
    align-items: center;
    border: var(--border-primary);
    border-radius: 8px;
    word-spacing: 20px;
  }

  &__to-page {
    display: flex;
    align-items: center;

    &-label {
      display: flex;
      align-items: center;
      border: var(--border-primary);
      border-right: 0;
      margin-right: -6px !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding: var(--size-small-x) var(--size-small-x);
      padding-right: calc(var(--size-small-x) + 7px);
      color: var(--body-placeholder);
    }

    &-input {
      width: 120px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0 !important;
      margin-right: -5px !important;
      background-color: var(--background-primary);

      &.Mui-focused {
        box-shadow: none !important;
        border: var(--border-primary) !important;
      }
    }
  }

  &__page {
    &-delimiter {
      margin: 0 var(--size-small-x);
      color: var(--body-placeholder);
    }
  }

  &__button {
    min-width: 0 !important;
    padding: var(--size-small) !important;

    &--prev-next {
      &:first-of-type {
        margin-right: var(--size-small);
      }

      &:last-of-type {
        margin-left: var(--size-small);
      }
    }

    path {
      fill: var(--body-primary);
    }
  }
}
