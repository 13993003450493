.balance-with-amount-hours-left {
  position: relative;
  display: flex;
  flex-direction: column;
  border: var(--border-primary);
  border-radius: 12px;
  padding: var(--size-medium);
  margin-bottom: var(--size-medium);

  &__amount {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--size-small-2x) var(--size-small);
    width: 100%;
    border: var(--border-primary);
    border-radius: 8px;
    margin-top: var(--size-small-x);
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    margin: 0 var(--size-small-x);

    path {
      stroke: var(--body-primary);
    }
  }
}
