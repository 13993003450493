// Sizes

$size-small-3x: 2px;
$size-small-2x: 4px;
$size-small-x: 8px;
$size-small: 12px;
$size-medium: 16px;
$size-large: 24px;
$size-large-2x: 40px;
$size-large-3x: 48px;
$size-large-4x: 64px;
$size-large-5x: 80px;
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
// stylelint-disable-next-line scss/no-global-function-names
$sizes: map-merge(
  (
    'size-small-3x': $size-small-3x,
    'size-small-2x': $size-small-2x,
    'size-small-x': $size-small-x,
    'size-small': $size-small,
    'size-medium': $size-medium,
    'size-large': $size-large,
    'size-large-2x': $size-large-2x,
    'size-large-3x': $size-large-3x,
    'size-large-4x': $size-large-4x,
    'size-large-5x': $size-large-5x,
  ),
  $sizes
);

:root {
  @each $name, $value in $sizes {
    --#{$name}: #{$value};
  }
}
