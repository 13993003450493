.top-up-balance-warning {
  display: flex;
  flex-direction: column;
  position: relative;
  border: var(--border-primary);
  border-radius: 12px;
  background-color: var(--background-secondary);
  margin-bottom: var(--size-medium);

  &__top {
    border-bottom: var(--border-primary);
    padding: var(--size-small) var(--size-medium);
  }

  &__body {
    display: flex;
    align-items: center;
    padding: var(--size-small) var(--size-medium);
  }

  &__icon {
    margin-right: var(--size-small);
  }
}
