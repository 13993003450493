.payment-steps-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--size-medium);
  margin-bottom: var(--size-medium);

  &__item {
    position: relative;
    padding-bottom: var(--size-small);

    &::after {
      position: absolute;
      display: block;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 2px;
      background-color: var(--background-secondary);
    }

    &.active::after {
      background-color: var(--brand-primary);
    }

    &.success::after {
      background-color: var(--success-primary);
    }

    &.awaiting::after {
      background-color: var(--warning-primary);
    }
  }
}
