$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
// stylelint-disable-next-line scss/no-global-function-names
$colors: map-merge(
  (
    /*gray*/
    'gray-0': #fff,
    'gray-50': #f5f5f5,
    'gray-100': #ebebeb,
    'gray-200': #dbdbdb,
    'gray-250': #ccc,
    'gray-300': #b8b8b8,
    'gray-400': #9e9e9e,
    'gray-500': #7a7a7a,
    'gray-600': #565656,
    'gray-700': #414141,
    'gray-750': #2e2e2e,
    'gray-800': #242424,
    'gray-900': #1c1c1c,
    'gray-950': #141414,
    'gray-1000': #0d0d0d,
    /*blue*/
    'blue-0': #f7fcff,
    'blue-50': #e2e6ff,
    'blue-100': #c9c4ff,
    'blue-200': #b0a9ff,
    'blue-250': #a59bff,
    'blue-300': #998eff,
    'blue-400': #8372ff,
    'blue-500': #6d56ff,
    'blue-600': #5a38eb,
    'blue-700': #480dcf,
    'blue-750': #3200a5,
    'blue-800': #260083,
    'blue-900': #1e0062,
    'blue-950': #0c0031,
    'blue-1000': #030014,
    'blue-alpha-1': #480dcf4d,
    'blue-alpha-2': #480dcf33,
    'blue-alpha-3': #480dcf1a,
    /*green*/
    'green-0': #e6fff3,
    'green-50': #b8ffd5,
    'green-100': #77f3ad,
    'green-200': #5bd894,
    'green-250': #4ccb88,
    'green-300': #3cbe7c,
    'green-400': #0fa464,
    'green-500': #008e50,
    'green-600': #006a2e,
    'green-700': #005218,
    'green-750': #003c01,
    'green-800': #002600,
    'green-900': #001b00,
    'green-950': #000e00,
    'green-1000': #000,
    'green-alpha-1': #0fa4644d,
    'green-alpha-2': #0fa46433,
    'green-alpha-3': #0fa4641a,
    /*orange*/
    'orange-0': #fffcdb,
    'orange-50': #ffe7a3,
    'orange-100': #ffd15f,
    'orange-200': #faa01b,
    'orange-250': #ec9300,
    'orange-300': #df8700,
    'orange-400': #c46e00,
    'orange-500': #a95500,
    'orange-600': #8f3d00,
    'orange-700': #7f2d00,
    'orange-750': #661200,
    'orange-800': #4d0000,
    'orange-900': #280000,
    'orange-950': #190000,
    'orange-1000': #050000,
    'orange-alpha-1': #faa01b4d,
    'orange-alpha-2': #faa01b33,
    'orange-alpha-3': #faa01b1a,
    /*orange*/
    'red-0': #ffeeec,
    'red-50': #ffcbc8,
    'red-100': #ff9fa3,
    'red-200': #ff8b90,
    'red-250': #ff6f78,
    'red-300': #ff5260,
    'red-400': #ed2d45,
    'red-500': #d0002d,
    'red-600': #b60017,
    'red-700': #990,
    'red-750': #840000,
    'red-800': #670000,
    'red-900': #4a0000,
    'red-950': #1e0000,
    'red-1000': #0b0000,
    'red-alpha-1': #ed2d454d,
    'red-alpha-2': #ed2d4533,
    'red-alpha-3': #ed2d451a
  ),
  $colors
);
$default-colors-set: (
  /*background*/
  'background-primary': 'var(--gray-0)',
  'background-secondary': 'var(--gray-50)',
  'background-tertiary': 'var(--gray-100)',
  'background-quaternary': 'var(--gray-200)',
  /*body*/
  'body-primary': 'var(--gray-1000)',
  'body-secondary': 'var(--gray-800)',
  'body-tertiary': 'var(--gray-700)',
  'body-placeholder': 'var(--gray-600)',
  'body-tint': 'var(--gray-0)',
  /*border*/
  'border-color-primary': 'var(--gray-200)',
  'border-color-secondary': 'var(--gray-250)',
  'border-color-tertiary': 'var(--gray-300)',
  /*shadow*/
  'shadow-color-primary': 'var(--gray-100)',
  /*brand*/
  'brand-primary': 'var(--blue-700)',
  'brand-secondary': 'var(--blue-600)',
  'brand-tertiary': 'var(--blue-750)',
  'brand-quaternary': 'var(--blue-800)',
  'brand-tint3': 'var(--blue-100)',
  'brand-tint2': 'var(--blue-50)',
  'brand-tint1': 'var(--blue-0)',
  'brand-alpha3': 'var(--blue-alpha-3)',
  'brand-alpha2': 'var(--blue-alpha-2)',
  'brand-alpha1': 'var(--blue-alpha-1)',
  /*error*/
  'error-primary': 'var(--red-400)',
  'error-secondary': 'var(--red-300)',
  'error-tertiary': 'var(--red-600)',
  'error-tint3': 'var(--red-900)',
  'error-tint2': 'var(--red-950)',
  'error-tint1': 'var(--red-1000)',
  'error-alpha3': 'var(--red-alpha-3)',
  'error-alpha2': 'var(--red-alpha-2)',
  'error-alpha1': 'var(--red-alpha-1)',
  /*success*/
  'success-primary': 'var(--green-400)',
  'success-secondary': 'var(--green-250)',
  'success-tertiary': 'var(--green-600)',
  'success-tint3': 'var(--green-900)',
  'success-tint2': 'var(--green-950)',
  'success-tint1': 'var(--green-1000)',
  'success-alpha3': 'var(--green-alpha-3)',
  'success-alpha2': 'var(--green-alpha-2)',
  'success-alpha1': 'var(--green-alpha-1)',
  /*warning*/
  'warning-primary': 'var(--orange-200)',
  'warning-secondary': 'var(--orange-100)',
  'warning-tertiary': 'var(--orange-500)',
  'warning-tint3': 'var(--orange-900)',
  'warning-tint2': 'var(--orange-950)',
  'warning-tint1': 'var(--orange-1000)',
  'warning-alpha3': 'var(--orange-alpha-3)',
  'warning-alpha2': 'var(--orange-alpha-2)',
  'warning-alpha1': 'var(--orange-alpha-1)'
);
$dark-colors-set: (
  /*background*/
  'background-primary': 'var(--gray-900)',
  'background-secondary': 'var(--gray-800)',
  'background-tertiary': 'var(--gray-750)',
  'background-quaternary': 'var(--gray-700)',
  /*body*/
  'body-primary': 'var(--gray-100)',
  'body-secondary': 'var(--gray-250)',
  'body-tertiary': 'var(--gray-400)',
  'body-placeholder': 'var(--gray-500)',
  'body-tint': 'var(--gray-0)',
  /*border*/
  'border-color-primary': 'var(--gray-700)',
  'border-color-secondary': 'var(--gray-600)',
  'border-color-tertiary': 'var(--gray-500)',
  /*shadow*/
  'shadow-color-primary': 'var(--gray-1000)',
  /*brand*/
  'brand-primary': 'var(--blue-500)',
  'brand-secondary': 'var(--blue-400)',
  'brand-tertiary': 'var(--blue-600)',
  'brand-quaternary': 'var(--blue-700)',
  'brand-tint3': 'var(--blue-900)',
  'brand-tint2': 'var(--blue-950)',
  'brand-tint1': 'var(--blue-1000)',
  'brand-alpha3': 'var(--blue-alpha-3)',
  'brand-alpha2': 'var(--blue-alpha-2)',
  'brand-alpha1': 'var(--blue-alpha-1)',
  /*error*/
  'error-primary': 'var(--red-400)',
  'error-secondary': 'var(--red-300)',
  'error-tertiary': 'var(--red-600)',
  'error-tint3': 'var(--red-900)',
  'error-tint2': 'var(--red-950)',
  'error-tint1': 'var(--red-1000)',
  'error-alpha3': 'var(--red-alpha-3)',
  'error-alpha2': 'var(--red-alpha-2)',
  'error-alpha1': 'var(--red-alpha-1)',
  /*success*/
  'success-primary': 'var(--green-400)',
  'success-secondary': 'var(--green-250)',
  'success-tertiary': 'var(--green-600)',
  'success-tint3': 'var(--green-900)',
  'success-tint2': 'var(--green-950)',
  'success-tint1': 'var(--green-1000)',
  'success-alpha3': 'var(--green-alpha-3)',
  'success-alpha2': 'var(--green-alpha-2)',
  'success-alpha1': 'var(--green-alpha-1)',
  /*warning*/
  'warning-primary': 'var(--orange-200)',
  'warning-secondary': 'var(--orange-100)',
  'warning-tertiary': 'var(--orange-500)',
  'warning-tint3': 'var(--orange-900)',
  'warning-tint2': 'var(--orange-950)',
  'warning-tint1': 'var(--orange-1000)',
  'warning-alpha3': 'var(--orange-alpha-3)',
  'warning-alpha2': 'var(--orange-alpha-2)',
  'warning-alpha1': 'var(--orange-alpha-1)'
);

:root {
  @each $name, $value in $colors {
    --#{$name}: #{$value};
  }

  @each $name, $value in $default-colors-set {
    --#{$name}: #{$value};
  }

  @for $i from 1 through 9 {
    // stylelint-disable-next-line scss/no-global-function-names
    --brand-primary-#{$i}0: #{map-get($colors, 'blue-700')}#{$i}0;
  }
}

.dark:root {
  @each $name, $value in $dark-colors-set {
    --#{$name}: #{$value};
  }

  @for $i from 1 through 9 {
    // stylelint-disable-next-line scss/no-global-function-names
    --brand-primary-#{$i}0: #{map-get($dark-colors-set, 'blue-600')}#{$i}0;
  }
}

@each $name, $value in $colors {
  .color-#{$name} {
    color: var(--#{$name}) !important;
    fill: var(--#{$name}) !important;
  }
  .bg-#{$name} {
    background-color: var(--#{$name}) !important;
  }
}

@each $name, $value in $default-colors-set {
  .color-#{$name} {
    color: var(--#{$name}) !important;
    fill: var(--#{$name}) !important;
  }
  .bg-#{$name} {
    background-color: var(--#{$name}) !important;
  }
}

.color-black {
  color: #000 !important;
}

.color-white {
  color: #fff !important;
}

.shadow-color {
  color: var(--shadow-color-primary);
}

.border-color-primary {
  color: var(--border-color-primary);
}

.border-color-secondary {
  color: var(--border-color-secondary);
}

.border-color-tertiary {
  color: var(--border-color-tertiary);
}

.bg-brand-primary {
  background-color: var(--brand-primary) !important;
  color: var(--body-tint) !important;
}

.hover-bg-primary-color:hover {
  background-color: var(--brand-primary) !important;
}

.hover-bg-secondary-color:hover {
  background-color: var(--background-secondary) !important;
}

.hover-bg-tertiary-color:hover {
  background-color: var(--background-tertiary) !important;
}
