@import './sidebar-head';
@import './user-balance';
@import './profile-menu';

.sidebar {
  @extend %custom-scroll-y;

  position: absolute;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  padding: var(--size-medium);
  padding-bottom: var(--size-small-2x);
  border-right: var(--border-primary);
  width: var(--sidebar-collapsed-width);
  min-width: 0;
  z-index: 999;

  .sidebarExpanded & {
    &:hover {
      width: var(--sidebar-width);
    }
  }

  .sidebarStatic & {
    width: var(--sidebar-width);
    min-width: var(--sidebar-width);
  }

  background: url('../../../images/sidebar-top-bg.png') no-repeat;
  background-size: 416px 242px;
  background-position: -155px -20px;
  background-color: var(--background-primary);
}
