@mixin tablet {
  @media (width >= 768px) and (width <= 1000px) {
    @content;
  }
}

@mixin mobile {
  @media (width <= 768px) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media (width <= 1000px) {
    @content;
  }
}
