.page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: var(--background-secondary);

  &__inner {
    position: relative;
    flex: 1 1 auto;
  }
}
