.auth-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 640px;

  &__head {
    margin-bottom: var(--size-large);
  }

  &__back-button svg path {
    fill: var(--body-primary);
  }

  &__body {
    position: relative;
    width: 100%;
  }
}
