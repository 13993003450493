.server-info-head {
  border-bottom: var(--border-primary);
  background-color: var(--background-primary);

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--size-small-x) var(--size-medium) var(--size-small) var(--size-medium);
  }

  &__tabs {
    padding: 0 var(--size-medium);
  }

  &__back {
    margin-right: var(--size-medium);

    &-btn {
      path {
        fill: var(--body-primary);
      }
    }
  }

  &__head {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
