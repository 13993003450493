.font-regular {
  font-weight: 400 !important;
}

.font-bold {
  font-weight: 600 !important;
}

.font-light {
  font-weight: 300 !important;
}

.default-letter-spacing,
%default-letter-spacing {
  letter-spacing: -0.02em;
}

$font-family: 'Inter', sans-serif;
$font-family-monospace: 'Inter', sans-serif;
$font-size-base: 1rem !default;
$font-weight-base: 400 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$dt-font-weight: $font-weight-bold !default;
$line-height-base: 1.5 !default;

:root {
  --font-family: #{$font-family};
  --font-family-monospace: #{$font-family-monospace};
}

.body-1,
%body-1 {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.32px;
}

.body-2,
%body-2 {
  font-family: $font-family;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.28px;
}

.body-3,
%body-3 {
  font-family: $font-family;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: -0.12px;
}

.label-1,
%label-1 {
  @extend %body-1;

  text-transform: uppercase;
}

.label-2,
%label-2 {
  @extend %body-2;

  text-transform: uppercase;
}

.label-3,
%label-3 {
  @extend %body-3;

  text-transform: uppercase;
}

.h1,
%h1 {
  font-family: $font-family;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  letter-spacing: -0.96px;
}

.h2,
%h2 {
  font-family: $font-family;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: -0.56px;
}

.h3,
%h3 {
  font-family: $font-family;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -0.48px;
}

.h4,
%h4 {
  font-family: $font-family;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.subtitle-1,
%subtitle-1 {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.32px;
}

.subtitle-2,
%subtitle-2 {
  font-family: $font-family;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.subtitle-3,
%subtitle-3 {
  font-family: $font-family;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  letter-spacing: -0.12px;
}

.label-1,
%labe-1 {
  @extend %subtitle-1;

  text-transform: uppercase;
  font-weight: 400;
}

.label-2,
%labe-2 {
  @extend %subtitle-2;

  text-transform: uppercase;
  font-weight: 400;
}

.label-3,
%labe-3 {
  @extend %subtitle-3;

  text-transform: uppercase;
  font-weight: 400;
}

.radius-4 {
  border-radius: 4px !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.radius-16 {
  border-radius: 16px !important;
}

.radius-50 {
  border-radius: 50% !important;
}
